<template>
  <list-container :list="orders" @load="loadMoreOrders" @query="queryOrders">
    <template v-slot:items>
      <div v-if="drawItems">
        <div v-for="(order, index) in orders.orders" :key="order.order_id" @click="detail(order.order_id)">
          <list-item-container :printBorder="index < count">
            <template v-slot:avatar>
              <v-icon class="avatar">mdi-shopping</v-icon>
            </template>
            <template v-slot:content>
              <div class="list-item-title first-capital-letter">
                Orden {{ order.order_number }}
              </div>
              <div class="creation-date">
                {{ dateFormat(order.creation_date, 'D MMM YYYY') }}
              </div>
              <div class="order-price">
                {{ getOrdersTotalPrice(index) }}
              </div>
              <div :class="'order-delivery status-delivery-' + getOrdersDeliveryStatus(index)">
                <v-icon class="order-delivery-icon">mdi-truck</v-icon>{{ getDeliveryStatusName(getOrdersDeliveryStatus(index)) }}
              </div>
              <div class="list-item-buyer">
                Comprado por {{ order.user.first_name }} {{ order.user.last_name }}
              </div>
            </template>
          </list-item-container>
        </div>
      </div>
    </template>
  </list-container>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import ListContainer from '@/components/List/ListContainer'
import ListItemContainer from '@/components/List/ListItemContainer'
import { dateFormat } from '@/shared/format'
import { getDeliveryStatusName } from '@/shared/delivery'

export default {
  mixins: [navigationMixin],
  components: {
    ListContainer,
    ListItemContainer
  },
  computed: {
    ...mapState('order', ['orders']),
    ...mapGetters('order', ['getOrdersTotalPrice', 'getOrdersDeliveryStatus']),
    drawItems () {
      return this.orders && this.orders.orders
    },
    count () {
      return this.orders.orders.length - 1
    }
  },
  methods: {
    dateFormat,
    getDeliveryStatusName,
    ...mapActions('order', ['getOrders', 'getMoreOrders']),
    detail (id) {
      this.goToRouteByName('order-detail', { id })
    },
    loadMoreOrders (url) {
      this.getMoreOrders(url)
    },
    queryOrders (search) {
      this.getOrders(search)
    }
  },
  mounted () {
    this.getOrders()
  }
}
</script>
<style scoped>
.avatar {
  color: var(--app-avatar-bg-color);
  font-size: 3rem;
}
.creation-date {
  text-align: left;
  font-size: 0.9rem;
}
.order-price {
  text-align: left;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 2rem;
}
.list-item-buyer {
  text-align: left;
  color: #646464;
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
}
.order-delivery {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}
.order-delivery-icon {
  color: var(--app-icon-active-color);
  font-size: 1.125rem;
  margin-right: 0.5rem;
}
.status-delivery-delivered {
  color: var(--app-color-delivery-delivered);
  font-weight: 600;
}
.status-delivery-returned {
  color: var(--app-color-delivery-returned);
  font-weight: 600;
}
/* 600px */
@media only screen and (max-width: 37.5rem) {
  .list-item-title {
    padding-top: 0.125rem;
    font-size: 0.938rem;
    line-height: 1.125rem;
  }
}
</style>
